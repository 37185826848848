import { crypt } from "./utils/cryptoLib";

export const PrivateRoutes = {
  PARAM_START_TIMER: "/",
  PARAM_LEVEL_ONE: "/levelOne",
  PARAM_LEVEL_TWO: "/levelTwo",
  PARAM_LEVEL_THREE: "/levelThree",
  PARAM_LEVEL_FOUR: "/levelFour",
  PARAM_LEVEL_FIVE: "/levelFive",
  PARAM_LEVEL_SIX: "/levelSix",
  PARAM_QUIZ_ONE: "/quizOne",
  PARAM_QUIZ_TWO: "/quizTwo",
  PARAM_QUIZ_THREE: "/quizThree",
  PARAM_QUIZ_FOUR: "/quizFour",
  PARAM_END_CLASSIC: "/stepEndClassic",
  TEST: "/test",
  PARAM_404: "*",
};

export const SecretAnswers = {
  ANSWER_ONE: "React",
  ANSWER_TWO: crypt("salt", "React"),
};
